<template>
  <v-container>
    <v-row class="mb-4">
      <h2>La Discussion pour la loi de Charles</h2>
    </v-row>
    <v-row>
      <p class="mb-3">
        a) Dans le tableau ci-dessous, insérez vos données expérimentales pour le volume et la
        température. Si vous n'avez qu'un essai, laissez la deuxième ligne vide.
      </p>
    </v-row>

    <v-row class="mb-7">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Run}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{V}_1\text{ (mL)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{T}_1\text{ (}^\circ\text{C)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{V}_\text{cw}\text{ (mL)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{V}_2\text{ (mL)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{T}_2\text{ (}^\circ\text{C)}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="1" />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.v11"
                type="number"
                :readonly="isMarking"
                @input="inputs.v11 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t11"
                type="number"
                :readonly="isMarking"
                @input="inputs.t11 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.vcw1"
                type="number"
                :readonly="isMarking"
                @input="inputs.vcw1 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.v21"
                type="number"
                :readonly="isMarking"
                @input="inputs.v21 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t21"
                type="number"
                :readonly="isMarking"
                @input="inputs.t21 = $event !== '' ? Number($event) : null"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="2" />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.v12"
                type="number"
                :readonly="isMarking"
                @input="inputs.v12 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t12"
                type="number"
                :readonly="isMarking"
                @input="inputs.t12 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.vcw2"
                type="number"
                :readonly="isMarking"
                @input="inputs.vcw2 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.v22"
                type="number"
                :readonly="isMarking"
                @input="inputs.v22 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t22"
                type="number"
                :readonly="isMarking"
                @input="inputs.t22 = $event !== '' ? Number($event) : null"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <v-row>
      <p class="mb-3">
        b) Utilisez l'information qui se trouve dans le tableau dans la partie a) afin de remplir le
        tableau ci-dessous. Remarquez que les températures utilisées pour calculer la constante pour
        la loi de Charles doivent avoir des unités de Kelvin.
      </p>
    </v-row>

    <v-row>
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Run}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{T}_1\text{ (K)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{T}_2\text{ (K)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{V}_1\text{/T}_1$" />
              <stemble-latex content="$\text{(mL/K)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{V}_2\text{/T}_2$" />
              <stemble-latex content="$\text{(mL/K)}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\% \text{ Error}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="1" />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t11k"
                type="number"
                :readonly="isMarking"
                @input="inputs.t11k = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t21k"
                type="number"
                :readonly="isMarking"
                @input="inputs.t21k = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.V1overT11"
                type="number"
                :readonly="isMarking"
                @input="inputs.V1overT11 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.V2overT21"
                type="number"
                :readonly="isMarking"
                @input="inputs.V2overT21 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.pctError1"
                type="number"
                :readonly="isMarking"
                @input="inputs.pctError1 = $event !== '' ? Number($event) : null"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="2" />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t12k"
                type="number"
                :readonly="isMarking"
                @input="inputs.t12k = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.t22k"
                type="number"
                :readonly="isMarking"
                @input="inputs.t22k = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.V1overT12"
                type="number"
                :readonly="isMarking"
                @input="inputs.V1overT12 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.V2overT22"
                type="number"
                :readonly="isMarking"
                @input="inputs.V2overT22 = $event !== '' ? Number($event) : null"
              />
            </td>
            <td>
              <v-text-field
                v-model.number="inputs.pctError2"
                type="number"
                :readonly="isMarking"
                @input="inputs.pctError2 = $event !== '' ? Number($event) : null"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <!--    <v-row>-->
    <!--      <p class="mb-2">-->
    <!--        c) What is the average V/T value for your experiment? Consider all values regardless of-->
    <!--        whether the volume corresponds to V<sub>1</sub> or V<sub>2</sub>.-->
    <!--      </p>-->
    <!--    </v-row>-->
    <!--    <v-row class="mb-5">-->
    <!--      <v-col cols="12">-->
    <!--        <calculation-input-->
    <!--          v-model.number="inputs.averageVoverT"-->
    <!--          type="number"-->
    <!--          :readonly="isMarking"-->
    <!--          prepend-text="$\text{V/T:}$"-->
    <!--          @input="inputs.averageVoverT = $event !== '' ? Number($event) : null"-->
    <!--        />-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <v-row class="mb-n3">
      <p>
        c) Insérez votre discussion sur la loi de Charles dans l'espace ci-dessous. Soyez certain de
        discuter vos résultats (qui comprennent le pourcentage d'erreur), si vos résultats ont
        vérifié la loi de Charles, des sources d'erreurs raisonnables, et d'autres points qui vous
        semblent pertinents à l'expérience. Remarquez que vous pouvez augmenter l'espace en cliquant
        sur le coin inférieur droit de la boîte et tirer le souris.
      </p>
    </v-row>

    <v-row class="mx-n6 mb-n12">
      <v-col cols="12">
        <s-textarea v-model="inputs.discussion" outlined label="Discussion" />
      </v-col>
    </v-row>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OttawaTask6',
  components: {
    AiLoadingOverlay,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        v11: null,
        t11: null,
        vcw1: null,
        v21: null,
        t21: null,
        v12: null,
        t12: null,
        vcw2: null,
        v22: null,
        t22: null,

        t11k: null,
        t21k: null,
        t12k: null,
        t22k: null,
        V1overT11: null,
        V1overT12: null,
        V2overT21: null,
        V2overT22: null,
        pctError1: null,
        pctError2: null,

        // averageVoverT: null,
        discussion: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
